import { useEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import './works.scss';

import axios from 'axios';

const { Meta } = Card;
function Works() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('https://api.cmvstv.com/platform/v1/getSmartWorks')
      .then(res => {
        setData(res.data.param);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <div className='main-width works-wrap'>
      <Row gutter={[40, 40]}>
        {
          data.map((item, index) => {
            return (
              <Col span={6} key={index}>
                <Card
                  hoverable
                  cover={<img alt={item.name} src={item.image} />}
                >
                  <Meta title={item.name} description={item.summary} />
                </Card>
              </Col>
            )
          })
        }
      </Row>
    </div>
  );
}

export default Works;