import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LayoutComponent from '@/components/layout';

import HomePage from '@/pages/home';
import NewsPage from '@/pages/news';
import AboutUs from '@/pages/about-us';
import Activity from '@/pages/activity';
import Works from '@/pages/works';
import Compaines from '@/pages/company';
import NewsDetail from '@/pages/news-detail';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LayoutComponent />}>
          <Route index element={<HomePage />}></Route>
          <Route path='news' element={<NewsPage />}></Route>
          <Route path='news-detail' element={<NewsDetail />}></Route>
          <Route path='about-us' element={<AboutUs />}></Route>
          <Route path='activity' element={<Activity />}></Route>
          <Route path='works' element={<Works />}></Route>
          <Route path='compaines' element={<Compaines />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
