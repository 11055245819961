import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Anchor, Row, Col, Card } from 'antd';
import './home.scss';

const { Meta } = Card;
function HomePage() {
  const navigate = useNavigate();
  return (
    <>
      <div className='home-banner'>
        <div className='banner-title'>
          <div className='main-width home-topic'>
            <h1 className='ttt'>天衍信息服务平台</h1>
            <div className='stt'>
              整合直播平台、网络主播和商家资源，通过建设并运营直播基地构建产业生态圈，
              提供艺人经纪、娱乐直播、网综网剧制作、自媒体网络视频节目制作、直播策化支持、
              引流促活、现场技术服务、多媒体平台分发、数据统计分析的整合营销运营服务。
            </div>
            <Button type='primary' size="large" className='banner-btn'>入驻咨询：0731-89915452</Button>
          </div>
        </div>
      </div>
      <div className='home-tabs'>
        <div className='main-width'>
          <Anchor
            affix={false}
            className='home-anchor'
            direction="horizontal"
            getCurrentAnchor={() => '#part-1'}
            items={[
              {
                key: 'part-1',
                href: '#part-1',
                title: '入驻对象及条件',
              },
              {
                key: 'part-2',
                href: '#part-2',
                title: '运营服务',
              },
              {
                key: 'part-3',
                href: '#part-3',
                title: '物理空间',
              }
            ]}
          />
        </div>
      </div>
      <div className='home-part'>
        <div className='main-width' id='part-1'>
          <div className='part-title'>管理服务对象</div>
          <div className='part-content'>
            <div className=''>◆ 网络直播类企业（含秀场直播、电商直播、游戏直播、体育竞技直播等）</div>
            <div className=''>◆ 为直播类公司提供配套服务的企业</div>
          </div>
          <div className='part-title'>入驻条件</div>
          <div className='part-content'>
            <div className=''>◆ 乙方需将公司工商、税务落户在甲方所运营的马栏山直播基地内</div>
            <div className=''>◆ 乙方入驻“直播基地”，乙方应按照与马栏山（长沙）视频文创园管委会约定的税收承诺完成税收，如乙方产业内容、缴纳税收等承诺达不到马栏山（长沙）视频文创园管委会考核要求，甲方有权解除合同，收回该场地</div>
          </div>
        </div>
        <div className='main-width' id='part-2'>
          <div className='part-title'>运营服务</div>
          <Row gutter={[30, 60]} className='service-row'>
            <Col span={6}>
              <div className='service-item'>
                <div className='service-item-num'>01</div>
                <div className='service-item-content'>主播培训</div>
              </div>
            </Col>
            <Col span={6}>
              <div className='service-item'>
                <div className='service-item-num'>02</div>
                <div className='service-item-content'>内容指导</div>
              </div>
            </Col>
            <Col span={6}>
              <div className='service-item'>
                <div className='service-item-num'>03</div>
                <div className='service-item-content'>包装宣传</div>
              </div>
            </Col>
            <Col span={6}>
              <div className='service-item'>
                <div className='service-item-num'>04</div>
                <div className='service-item-content'>活动策划</div>
              </div>
            </Col>
            <Col span={6}>
              <div className='service-item'>
                <div className='service-item-num'>05</div>
                <div className='service-item-content'>视频推广</div>
              </div>
            </Col>
            <Col span={6}>
              <div className='service-item'>
                <div className='service-item-num'>06</div>
                <div className='service-item-content'>资源扶持</div>
              </div>
            </Col>
            <Col span={6}>
              <div className='service-item'>
                <div className='service-item-num'>07</div>
                <div className='service-item-content'>奖励政策</div>
              </div>
            </Col>
            <Col span={6}>
              <div className='service-item'>
                <div className='service-item-num'>08</div>
                <div className='service-item-content'>平台对接</div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='main-width' id='part-3'>
          <div className='part-title'>物理空间</div>
          <Row gutter={[20, 20]} className='service-row'>
            <Col span={4}>
              <Card
                cover={<img className='image' src="https://n.sinaimg.cn/sinakd20211229s/166/w1080h686/20211229/e2bd-fe7f7040aeb4d337db7beeec4f0b2320.jpg" />}
              >
                <Meta title="网络直播间" />
              </Card>
            </Col>
            <Col span={4}>
              <Card
                cover={<img className='image' src="https://img1.baidu.com/it/u=4260505778,3839164526&fm=253&fmt=auto&app=138&f=JPEG?w=749&h=500" />}
              >
                <Meta title="智能办公区" />
              </Card>
            </Col>
            <Col span={4}>
              <Card
                cover={<img className='image' src="https://img2.baidu.com/it/u=3301813545,2888578988&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500" />}
              >
                <Meta title="综艺互动直播厅" />
              </Card>
            </Col>
            <Col span={4}>
              <Card
                cover={<img className='image' src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2FO1CN01eWguxF1enZlwI8YR6_%21%212213462283916-0-cib.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1716448114&t=1965a0bf0ba2e837d6e356aa949b91e3" />}
              >
                <Meta title="多功能化妆区" />
              </Card>
            </Col>
            <Col span={4}>
              <Card
                cover={<img className='image' src="https://pic2.zhimg.com/v2-a9b1d00a46140aa24c327dc602aa7699_r.jpg" />}
              >
                <Meta title="众创共享区" />
              </Card>
            </Col>
            <Col span={4}>
              <Card
                cover={<img className='image' src="https://p5.itc.cn/images01/20200520/17ac342cc6b644d8a5e5f34ca5fd95ac.jpeg" />}
              >
                <Meta title="技术设备区" />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <div className='home-news'>
        <div className='main-width'>
          <div className='news-topic'>企业新闻</div>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <div className='news-item' onClick={
                () => {
                  navigate('/news-detail', {
                    state: {
                      title: '全国政协委员、广州市政协副主席姚建明一行莅临园区考察调研',
                      coverImg: require('@/assets/news/1.jpg'),
                      content: `<p>2024年5月16日，全国政协委员、广州市政协副主席，民建广东省委会副主委、广州市委会主委姚建明一行莅临马栏山众创园，考察调研园区文化产业生态及运营情况。</p><p><img src=${require('@/assets/news/2.jpg')} alt='' /></p><p><img src=${require('@/assets/news/3.jpg')} alt='' /></p><p>考察组一行先后参观了“星”数据交汇中心、新阶层学习社等地，草莓V视负责人向考察组一行详细介绍了园区运营服务、人才就业、招商政策等情况。</p><p><img src=${require('@/assets/news/4.jpg')} alt='' /></p><p>考察组一行随后参观了园区入驻企业博胜集团，与该企业负责人进行了沟通交流，实地感受了园区在文创、视频等领域的创新及成果。</p><p><img src=${require('@/assets/news/5.jpg')} alt='' /></p>`
                    }
                  });
                }
              }>
                <div className='news-item-image'>
                  <img className='img' src={require('@/assets/news/1.jpg')} alt='' />
                </div>
                <div className='news-intro'>
                  <div className='news-title'>全国政协委员、广州市政协副主席姚建明一行莅临园区考察调研</div>
                  <div className='news-desc'>2024年5月16日，全国政协委员、广州市政协副主席，民建广东省委会副主委、广州市委会主委姚建明一行莅临马栏山众创园，考察调研园区文化产业生态及运营情况。</div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className='news-item' onClick={
                () => {
                  navigate('/news-detail', {
                    state: {
                      title: '流程管理师训练营',
                      coverImg: require('@/assets/news/6.jpg'),
                      content: `<p>在数字化浪潮席卷全球的今天，高效、精准的流程管理已成为企业竞争力的核心。为了帮助大家更好地掌握流程管理的精髓，提升个人及团队的工作效率，由草莓V视与泰锐普咨询携手打造的“流程管理师训练营”2024年5月17日正式开营!</p><p><img src=${require('@/assets/news/6.jpg')} alt='' /></p><p>此次训练营主要依托泰锐普咨询丰富的行业经验和专业资源，结合园区入驻企业团队及个人的实际需求，为大家带来一系列深入浅出的流程管理课程。</p><p><img src=${require('@/assets/news/7.jpg')} alt='' /></p><p>王立中老师</p><p>深圳市格物流程研究院 院长/华为首批IPD流程引导者</p><p>华为大学流程研发管理课程开发者</p><p>23年企业流程管理研究实战经验/美国项目管理协会PMP认证</p><p><img src=${require('@/assets/news/8.jpg')} alt='' /></p><p><img src=${require('@/assets/news/9.jpg')} alt='' /></p><p>课后，学员们纷纷表示，王立中老师授课通俗易懂，能把专业知识风趣幽默的展示出来，让学员乐在其中的同时吸收知识，非常实用，同时，也感谢园区能提供这样的学习机会。</p>`
                    }
                  });
                }
              }>
                <div className='news-item-image'>
                  <img className='img' src={require('@/assets/news/6.jpg')} alt='' />
                </div>
                <div className='news-intro'>
                  <div className='news-title'>流程管理师训练营</div>
                  <div className='news-desc'>在数字化浪潮席卷全球的今天，高效、精准的流程管理已成为企业竞争力的核心。为了帮助大家更好地掌握流程管理的精髓，提升个人及团队的工作效率，由草莓V视与泰锐普咨询携手打造的“流程管理师训练营”2024年5月17日正式开营!</div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className='news-item' onClick={
                () => {
                  navigate('/news-detail', {
                    state: {
                      title: '奋进新征程 E新向未来',
                      coverImg: require('@/assets/news/10.jpg'),
                      content: `<p>2024年5月15日，“奋进新征程，E新向未来”——2023年“寻美·中国”主题活动总结暨2024年“寻美·中国”主题活动启动大会在中国天津成功举办，中央统战部副部长王瑞军出席了活动并做大会致辞。</p><p><img src=${require('@/assets/news/11.jpg')} alt=''/></p><p>“寻美·中国”主题活动开展三年来，寻美账号扩展覆盖到了全国31个省市，寻美中国累计阅读量突破480亿。湖南省全省相关寻美话题总阅读量超过8000W。</p><p><img src=${require('@/assets/news/12.jpg')} alt=''/></p><p>大会表彰了2023年“寻美·中国”表现突出的单位和个人，其中包括：</p><p><img src=${require('@/assets/news/13.jpg')} alt='' /></p><p>“寻美湖南”获得省级活动组织-优秀单位奖</p><p><img src=${require('@/assets/news/14.jpg')} alt='' /></p><p>“寻美中国”主题活动启动大会线下参与获得2023年度寻美中国地市级线下优秀活动</p><p><img src=${require('@/assets/news/15.jpg')} alt='' /></p><p>“寻美湖南”编辑获得省市地方站表扬</p><p>寻美湖南分会场设立在长沙市马栏山众创园多功能厅，湖南省新联会的相关负责同志和一众代表人士齐聚一堂，共同观看了大会直播。</p><p><img src=${require('@/assets/news/16.jpg')} alt='' /></p><p><img src=${require('@/assets/news/17.jpg')} alt='' /></p>`
                    }
                  })
                }
              }>
                <div className='news-item-image'>
                  <img className='img' src={require('@/assets/news/10.jpg')} alt='' />
                </div>
                <div className='news-intro'>
                  <div className='news-title'>奋进新征程 E新向未来</div>
                  <div className='news-desc'>2024年5月15日，“奋进新征程，E新向未来”——2023年“寻美·中国”主题活动总结暨2024年“寻美·中国”主题活动启动大会在中国天津成功举办，中央统战部副部长王瑞军出席了活动并做大会致辞。</div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className='news-item' onClick={() => {
                navigate('/news-detail', {
                  state: {
                    title: '马栏山众创园组织开展消防安全演练活动',
                    coverImg: require('@/assets/news/18.jpg'),
                    content: `<p>2024年5月9日，马栏山众创园举行了一场重要的消防安全演练活动，旨在提高园区工作人员及企业员工的火灾安全意识和应急反应能力。这次演练不仅加深了大家对消防安全知识的理解，还强化了紧急状况下的自我保护能力。</p><p>消防员在现场指导大家如何正确使用灭火器和消防栓，以及如何在烟雾环境中保持冷静并快速逃生。</p><p><img src=${require('@/assets/news/19.jpg')} alt=''/></p><p><img src=${require('@/assets/news/20.jpg')} alt=''/></p><p>本次演练不仅是一次应急响应能力的检验，也是对园区安全管理水平的一次全面提升。我们呼吁园区企业和个人都能重视日常的安全防范工作，提高防灾减灾能力，共同构建安全和谐的工作与生活环境。</p><p><img alt='' src=${require('@/assets/news/21.jpg')} /></p><p>我们也将将继续积极推动安全文化的建设，通过更多此类实战演练，加强园区的整体安全管理，确保每一位园区用户的生命财产安全。</p>`
                  }
                })
              }}>
                <div className='news-item-image'>
                  <img className='img' src={require('@/assets/news/18.jpg')} alt='' />
                </div>
                <div className='news-intro'>
                  <div className='news-title'>马栏山众创园组织开展消防安全演练活动</div>
                  <div className='news-desc'>2024年5月9日，马栏山众创园举行了一场重要的消防安全演练活动，旨在提高园区工作人员及企业员工的火灾安全意识和应急反应能力。这次演练不仅加深了大家对消防安全知识的理解，还强化了紧急状况下的自我保护能力。</div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className='news-item' onClick={() => {
                navigate('/news-detail', {
                  state: {
                    title: '威海市文登区西部工业园党组副书记鞠红伟一行莅临园区考察交流',
                    coverImg: require('@/assets/news/22.jpg'),
                    content: `<p>考察组一行参观了园区“星”数据交汇中心、e星实践创新基地等区域，并对目前基地运营工作进行了详细了解及深入沟通交流。</p><p><img src=${require('@/assets/news/23.jpg')} alt='' /></p><p>草莓V视副总经理胡英向考察组一行详细介绍了园区运营服务、人才就业等情况。</p>`
                  }
                })
              }}>
                <div className='news-item-image'>
                  <img className='img' src={require('@/assets/news/22.jpg')} alt='' />
                </div>
                <div className='news-intro'>
                  <div className='news-title'>威海市文登区西部工业园党组副书记鞠红伟一行莅临园区考察交流</div>
                  <div className='news-desc'>2024年5月10日，威海市文登区西部工业园党组副书记鞠红伟一行莅临马栏山众创园考察交流。</div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className='news-item' onClick={() => {
                navigate('/news-detail', {
                  state: {
                    title: '广州市越秀区人民政府区长罗光华一行莅临园区考察调研',
                    coverImg: require('@/assets/news/24.jpg'),
                    content: `<p>4月2日，马栏山视频文创产业园领导向调研组一行详细介绍园区发展、专业布局、新技术与文化产业深度融合等情况。</p><p><img src=${require('@/assets/news/25.jpg')} alt='' /></p><p>随后调研组一行走进马栏山“火石计划”超高清云化共享制作中心和乐田智作9号棚，详细了解了节目生产全流程、软硬件研发、平台研发应用、舞台机械、艺术灯光等，就XR虚拟影棚建设问题进行了交流。</p><p><img src=${require('@/assets/news/26.jpg')} alt='' /></p><p>4月3日，调研组一行前往马栏山众创园，草莓V视负责人向调研组一行详细介绍了园区运营服务、人才就业、招商政策等情况；</p><p><img src=${require('@/assets/news/27.jpg')} alt='' /></p><p>调研组一行随后参观了园区入驻企业博胜集团，与该企业负责人进行了沟通交流，实地感受了园区在文创、视频等领域的创新及成果。</p>`
                  }
                })
              }}>
                <div className='news-item-image'>
                  <img className='img' src={require('@/assets/news/24.jpg')} alt='' />
                </div>
                <div className='news-intro'>
                  <div className='news-title'>广州市越秀区人民政府区长罗光华一行莅临园区考察调研</div>
                  <div className='news-desc'>2024年4月2日至3日，广州市越秀区人民政府区长罗光华一行莅临马栏山视频文创产业园，考察调研马栏山视频文创产业园文化产业生态及产业技术。</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
};

export default HomePage;