import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { ConfigProvider } from "antd";
import zhCN from "antd/locale/zh_CN";
import 'dayjs/locale/zh-cn';
import 'antd/dist/reset.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
    locale={zhCN}
    theme={{
      token: {
        colorPrimary: '#1677ff'
      },

    }}
  >
    <App />
  </ConfigProvider>
);
