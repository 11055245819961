import './footer.scss';
import { Divider, Row, Col } from 'antd';

function FooterComponent() {
  return (
    <div className="main-width footer-container">
      {/* <Row gutter={40} className='footer-row'>
        <Col span={8}>
          <div>关于我们</div>
        </Col>
      </Row>
      <Divider className='footer-divider' /> */}
      <div className='friends-link'>
        <div className='footer-title'>友情链接：</div>
        <Divider type="vertical" />
        <a href='https://www.cmvstv.com' target='_blank' rel='noreferrer'>草莓V视</a>
      </div>
    </div>
  );
}

export default FooterComponent;