import { Row, Col } from 'antd';

import './about-us.scss';

function AboutUs() {
  const img = require('@/assets/images/2.png');

  return (
    <>
      <div className="main-width">
        <div className='about-wrap'>
          <div className='about-comp-wrap'>
            <div className='img-wrap'>
              <img src={img} alt='' className='image' />
            </div>
            <div className='about-desc'>
              <div className='about-title'>长沙天衍文化传播有限公司</div>
              <div className='about-intro'>成立于2018年7月12日，以打造网络主播为主，整合直播平台、网络主播和商家资源，通过建设并运营直播基地构建产业生态圈，
                提供艺人经济、娱乐直播、网综网剧制作、自媒体网络视频节目制作、直播策划支持、引流促活、现场技术服务、多媒体平台分发、数据统计分析的整合营销运营服务，
                是一家提供直播全产业链的新型传媒公司。</div>
            </div>
          </div>
        </div>
        <div className='about-static'>
          <Row gutter={[10, 0]}>
            <Col span={4} offset={4}>
              <div className='static-box'>
                <div className='static-number'>
                  <span className='number'>20</span>
                </div>
                <div className='tit'>活动场次</div>
              </div>
            </Col>
            <Col span={4}>
              <div className='static-box'>
                <div className='static-number'>
                  <span className='number'>6000</span>
                  <span className='unit'>万元</span>
                </div>
                <div className='tit'>总投资</div>
              </div>
            </Col>
            <Col span={4}>
              <div className='static-box'>
                <div className='static-number'>
                  <span className='number'>60</span>
                </div>
                <div className='tit'>引进企业</div>
              </div>
            </Col>
            <Col span={4} offset={6}>
              <div className='static-box'>
                <div className='static-number'>
                  <span className='number'>20</span>
                  <span className='unit'>亿元</span>
                </div>
                <div className='tit'>营收</div>
              </div>
            </Col>
            <Col span={4}>
              <div className='static-box'>
                <div className='static-number'>
                  <span className='number'>3000</span>
                  <span className='unit'>万元</span>
                </div>
                <div className='tit'>税收</div>

              </div>
            </Col>
            <Col span={4} offset={8}>
              <div className='static-box'>
                <div className='static-number'>
                  <span className='number'>1000</span>
                </div>
                <div className='tit'>主播人数</div>

              </div>
            </Col>
            <Col span={4}>
              <div className='static-box'>
                <div className='static-number'>
                  <span className='number'>2500</span>
                </div>
                <div className='tit'>就业岗位数量</div>

              </div>
            </Col>
            <Col span={4}>
              <div className='static-box'>
                <div className='static-number'>
                  <span className='number'>200</span>
                </div>
                <div className='tit'>运营工作人员</div>

              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='about-other'>
        <div className='main-width about-other-block'>
          <div className='about-block'>
            <div className='about-content'>
              直播孵化运营——致力于挖掘直播商业资源，全方位打造“马栏山直播”品牌价值。
            </div>
          </div>
          <div className='about-block'>
            <div className='about-content'>
              以直播基地为载体，以直播运营服务为核心，因人示教，全 方位包装打造“马栏山直播 ”品牌，实现最大 IP 值和价值转化。
            </div>
          </div>
          <div className='about-block'>
            <div className='about-content'>
              依托直播人才资源，与直播平台、经纪公司、网络 影视剧制作单位建立双向合作。
            </div>
          </div>
          <div className='about-block'>
            <div className='about-content'>
              <div>整合行业资源，培育视频直播新锐</div>
              <div>降低创业成本，提高创业项目存活率</div>
              <div>创新经济引擎，促进长沙经济发展</div>
            </div>
          </div>
        </div>
      </div>
      <div className='main-width park-intro'>
        <div className='park-desc'>
          <div className='park-desc-inner'>
            马栏山直播产业基地项目是一个综合性网络直播平台，是马 栏山视频文创产业园的重要组成部分，基地建设内容主要包括物 理空间和运营服务两大部分；物理空间包括网络直播间、智能办 公区、综艺互动直播厅、多功能化妆区、众创共享区、技术设备 区等办公面积的装修、家具和直播设备；运营服务主要包括主播 培训、 内容指导、包装宣传、活动策划、视频推广、资源扶持和 奖励政策等主播培养体系建设，同时对接各大直播等平台，实现 内容输出和变现，通过专业的规划建设，基地将最终成为湖南直
            播产业集中地，整合国内直播产业链，并辐射至全国。
          </div>
        </div>
      </div>
    </>
  )
};

export default AboutUs;