import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Breadcrumb } from 'antd';
import axios from 'axios';
import './news-detail.scss';

function NewsDetail() {
  const location = useLocation();

  const [detailData, setDetailData] = useState({});

  useEffect(() => {
    console.log(location.state);
    if (location.state?.id) {
      getDetailData(location.state.id);
    } else {
      setDetailData(location.state);
    }
  }, []);

  const getDetailData = (id) => {
    axios.get(`https://api.cmvstv.com/platform/v1/getBaseNew?id=${id}`).then(
      res => {
        setDetailData(res.data.param);
      }
    ).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      <div className="detail-news-wrap">
        <div className="bread-wrap">
          <Breadcrumb
            items={[
              {
                title: <a href="/">首页</a>,
              },
              {
                title: <a href="/news">新闻</a>,
              },
              {
                title: detailData.title,
              },
            ]}
          />
        </div>
        <h3 className="detail-title">{detailData.title}</h3>
        <img src={detailData.coverImg || detailData.image} alt={detailData.title} />
        <div dangerouslySetInnerHTML={{ __html: detailData.content }}></div>
      </div>
    </>
  )
};

export default NewsDetail;