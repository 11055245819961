import { useEffect, useState } from "react";
import { Card, Row, Col, List, Avatar, Space } from "antd";
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import './activity.scss';
import axios from 'axios';

SwiperCore.use([Navigation, Autoplay, FreeMode]);

function Activity() {

  const [listData , setListData] = useState([]);
  const [sData, setSData] = useState([]);
  
  useEffect(() => {
    getActivities();
    getBdData();
  }, [])

  const getActivities = () => {
    axios.get('https://api.cmvstv.com/platform/v1/getCurrency?typeId=bc').then(
      res => {
        // console.log(res);
        setListData(res.data.param);
      }
    ).catch(err => {
      console.log(err);
    })
  }

  const getBdData = () => {
    axios.get('https://api.cmvstv.com/platform/v1/getCurrency?typeId=bd').then(
      res => {
        // console.log(res);
        setSData(res.data.param);
      }
    ).catch(err => {
      console.log(err);
    })
  }


  return (
    <>
      <div className="main-width">
        <Row gutter={40} className="activity-wrap">
          <Col span={16}>
            <Row gutter={[40, 40]}>
              <Col span={24}>
                <Swiper
                  style={{ height: 400 }}
                  modules={[FreeMode, Navigation]}
                  loop
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  className='news-swiper'
                >
                  {
                    sData.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className='slide-content'>
                            <img src={item.image} />
                            <div className='slide-content-desc'>
                              <div className='slide-content-title'>{item.title}</div>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              </Col>
              <Col span={24}>
                <Card title="热门推荐">
                  <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                      onChange: (page) => {
                        console.log(page);
                      },
                      pageSize: 10,
                    }}
                    dataSource={listData}
                    renderItem={(item) => (
                      <List.Item
                        key={item.title}
                        extra={
                          <img
                            width={272}
                            alt="logo"
                            src={item.image}
                          />
                        }
                      >
                        <List.Item.Meta
                          title={<a href={item.url}>{item.title}</a>}
                          description={item.subtitle}
                        />
                        {item.content}
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Card title="最新发布">
              <List
                itemLayout="horizontal"
                dataSource={listData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<a href={item.url}>{item.title}</a>}
                      description={item.subtitle}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
};

export default Activity;