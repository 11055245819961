import { useEffect, useState } from 'react';
import { Row, Col, Card } from 'antd';
import axios from 'axios';
import './company.scss';

function Compaines() {
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    axios.get('https://sadmin.cmvstv.com/smartapi/index/getSmartCompany')
      .then(res => {
        setCompanyList(res.data.param);
      })
      .catch(err => { 
        console.log(err);
      })
  }, [])
return (
  <div className='main-width company-wrap'>
    <Row gutter={[40, 40]}>
      {
        companyList.map((item, index) => {
          return (
            <Col span={6} key={index}>
              <Card>
                <img className='logoImage' title={item.name} src={item.logoImageUrl} alt={item.subName} />
              </Card>
            </Col>
          )
        })
      }
    </Row>
  </div>
);
}

export default Compaines;