import { Link, useLocation } from "react-router-dom";

function Navbar() {

  const location = useLocation();
  const { pathname } = location;
  
  return (
    <>
      <div className="header-nav-bar">
        <Link to={'/'} className={pathname === '/' ? "nav-link is-active" : 'nav-link'}>首页</Link>
        <Link to={'/news'} className={pathname === '/news' ? "nav-link is-active" : 'nav-link'}>新闻</Link>
        <Link to={'/compaines'} className={pathname === '/compaines' ? "nav-link is-active" : 'nav-link'}>入驻企业</Link>
        <Link to={'/activity'} className={pathname === '/activity' ? "nav-link is-active" : 'nav-link'}>活动</Link>
        <Link to={'/works'} className={pathname === '/works' ? "nav-link is-active" : 'nav-link'}>优秀作品</Link>
        <Link to={'/about-us'} className={pathname === '/about-us' ? "nav-link is-active" : 'nav-link'}>关于我们</Link>
      </div>
    </>
  )
};

export default Navbar;