import { Outlet } from 'react-router-dom';
import {Layout} from 'antd';
import Navbar from './navbar';
import FooterComponent from '@/components/footer/footer';
const {Header, Footer, Sider, Content} = Layout;
function LayoutComponent() {
  return (
    <>
      <Layout className='layout-container'>
        <Header className='layout-header'>
          <div className='logo-title'>天衍信息服务平台</div>
          <Navbar />
        </Header>
        <Content className='layout-content'>
          <Outlet />
        </Content>
        <Footer className='layout-footer'>
          <FooterComponent />
        </Footer>
      </Layout>
    </>
  )
};

export default LayoutComponent;